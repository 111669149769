import { useState, useEffect } from "react";
import axios from "axios";
import { TabPanel, useTabs } from "react-headless-tabs";
import PropTypes from "prop-types";
import "./tailwind.css";
import "./App.css";
//import sslChecker from "./sslchecker";
//import axiotext2Translate from "./translate";

// for react-liquid-gauge
import LiquidGauge from "./gauge.js";

function App() {
  const [target, setTarget] = useState("");
  const [page, setPage] = useState(null);
  const [page_mobile, setPageMobile] = useState(null);
  const [isloading, setisloading] = useState(false);
  const [isloadingmobile, setisloadingmobile] = useState(false);
  const [isAMP, setisAMP] = useState(false);
  const [isloadingAMP, setisloadingAMP] = useState(false);
  const [error_message, setErrorMessage] = useState("");
  const [selectedTab, setSelectedTab] = useTabs(["行動裝置", "電腦"]);
  const [webURL, setWebURL] = useState("");
  const [isImgAltExpanded, setImgAltExpanded] = useState(false);
  const [schemaData, setSchemaData] = useState(null);
  const [isSchema, setisSchema] = useState(false);
  const [isloadingSchema, setisloadingSchema] = useState(false);  
  const [isSchemaExpanded, setSchemaExpanded] = useState(false);
  const [schemaORGData, setSchemaORGData] = useState(null);
  const [isSchemaORG, setisSchemaORG] = useState(false);
  const [isloadingSchemaORG, setisloadingSchemaORG] = useState(false);    
  const [isSchemaORGExpanded, setSchemaORGExpanded] = useState(false);
  const [faviconData, setFaviconData] = useState(null);
  const [isFavicon, setisFavicon] = useState(false);
  const [isloadingFavicon, setisloadingFavicon] = useState(false);   
  const [isFaviconExpanded, setFaviconExpanded] = useState(false);
  const [sitemapData, setSitemapData] = useState(null);
  const [isSitemap, setisSitemap] = useState(false);
  const [isloadingSitemap, setisloadingSitemap] = useState(false);   
  const [headingtagsData, setHeadingtagsData] = useState(null);
  const [isHeadingtags, setisHeadingtags] = useState(false);
  const [isloadingHeadingtags, setisloadingHeadingtags] = useState(false);     
  const [isHeadingTagsExpanded, setHeadingTagsExpanded] = useState(false);
  const [keywordsData, setKeywordsData] = useState(null);
  const [isKeywords, setisKeywords] = useState(false);
  const [isloadingKeywords, setisloadingKeywords] = useState(false);    
  const [isKeywordsExpanded, setKeywordsExpanded] = useState(false);
  const [backlinksData, setBacklinksData] = useState(null);
  const [isBacklinks, setisBacklinks] = useState(false);
  const [isloadingBacklinks, setisloadingBacklinks] = useState(false);    
  const [isBacklinksExpanded, setBacklinksExpanded] = useState(false);  

  const TabSelector = ({ isActive, children, onClick }) => (
    <button
      className={`mr-8 group inline-flex items-center px-2 py-4 border-b-2 font-medium text-sm leading-5 cursor-pointer whitespace-nowrap ${
        isActive
          ? "border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700"
          : "border-transparent text-gray-500 hover:text-gray-600 hover:border-gray-300 focus:text-gray-600 focus:border-gray-300"
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );

  TabSelector.propTypes = {
    isActive: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  const axiospagespeed = async () => {
    await axios
      .get(
        "https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=" +
          target +
          "&strategy=desktop" +
          "&category=performance" +
          "&category=accessibility" +
          "&category=best_practices" +
          "&category=seo" +
          "&category=pwa" +
          "&locale=zh-tw" +
          "&key=AIzaSyBe0PX12AAPJxI9U2l5mcOOQyGerJ-j0LA"
      )
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
          console.log(errors.message);
          setErrorMessage(
            "無法解析 " + target + "。建議您檢查這個網址是否有效。"
          );
          if (page) {
            setPage(null);
          }
          setTarget("");
          setisloading(false);
          setImgAltExpanded(false);
          console.log("done desktop with errors!");
        } else {
          //console.log("Desktop Data:");
          //console.log(data);
          setErrorMessage("");
          setPage(data);
          setTarget("");
          setisloading(false);
          setImgAltExpanded(false);
          console.log("done with desktop!");
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        setErrorMessage(
          "無法解析 " + target + "。建議您檢查這個網址是否有效。"
        );
        if (page) {
          setPage(null);
        }
        setTarget("");
        setisloading(false);
        setImgAltExpanded(false);
        console.log("done desktop with error!");
      });
  };

  const axiospagespeedmobile = async () => {
    await axios
      .get(
        "https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=" +
          target +
          "&strategy=mobile" +
          "&category=performance" +
          "&category=accessibility" +
          "&category=best_practices" +
          "&category=seo" +
          "&category=pwa" +
          "&locale=zh-tw" +
          "&key=AIzaSyBe0PX12AAPJxI9U2l5mcOOQyGerJ-j0LA"
      )
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
          console.log(errors.message);
          setErrorMessage(
            "無法解析 " + target + "。建議您檢查這個網址是否有效。"
          );
          if (page_mobile) {
            setPageMobile(null);
          }
          setTarget("");
          setisloadingmobile(false);
          setImgAltExpanded(false);
          console.log("done mobile with errors!");
        } else {
          //console.log("Mobile Data:");
          //console.log(data);
          setErrorMessage("");
          setPageMobile(data);
          setTarget("");
          setisloadingmobile(false);
          setImgAltExpanded(false);
          console.log("done with mobile!");
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        setErrorMessage(
          "無法解析 " + target + "。建議您檢查這個網址是否有效。"
        );
        if (page_mobile) {
          setPageMobile(null);
        }
        setTarget("");
        setisloadingmobile(false);
        setImgAltExpanded(false);
        console.log("done mobile with error!");
      });
  };

  const checkAMP = async () => {
    await axios
      .post(
        "https://acceleratedmobilepageurl.googleapis.com/v1/ampUrls:batchGet" +
          "?key=AIzaSyBe0PX12AAPJxI9U2l5mcOOQyGerJ-j0LA",
        {
          urls: [target],
          lookupStrategy: "FETCH_LIVE_DOC",
        }
      )
      .then(({ data, errors }) => {
        if (errors) {
          setisloadingAMP(false);
          if (isAMP) setisAMP(false);
          console.log("errors:");
          console.error(errors);
          console.log(errors.message);
          console.log("done AMP with errors!");
        } else {
          setisloadingAMP(false);
          if (!data["ampUrls"]) {
            //console.log("AMP: No");
            //console.log(data["urlErrors"].length);
            //console.log(data["urlErrors"][0].errorCode);
            setisAMP(false);
          } else {
            //console.log("AMP: Yes");
            setisAMP(true);
          }
          console.log("done with AMP!");
        }
      })
      .catch((error) => {
        setisloadingAMP(false);
        if (isAMP) setisAMP(false);
        console.log("error:");
        console.log(error);
        console.log(error.message);
        console.log("done AMP with error!");
      });
  };

  const checkSchema = async () => {
    await axios
      .get(
        "https://www.googleapis.com/customsearch/v1?q=" +
          target +
          "&cx=835743357449e4a9a" +
          "&key=AIzaSyBe0PX12AAPJxI9U2l5mcOOQyGerJ-j0LA"
      )
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
          console.log(errors.message);
          if (schemaData) {
            setSchemaData(null);
          }
          if (isSchema) setisSchema(false);
          setSchemaExpanded(false);
          setisloadingSchema(false);
          console.log("done schema with errors!");
        } else {
          //console.log("Schema:");
          //console.log(data);
          setSchemaData(data);
          if (data.items[0].pagemap.hasOwnProperty("metatags")) {
            setisSchema(true);
            //console.log("YES");
          } else {
            setisSchema(false);
            //console.log("NO");
          }
          setSchemaExpanded(false);
          setisloadingSchema(false);
          console.log("done with schema!");
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        if (schemaData) {
          setSchemaData(null);
        }
        if (isSchema) setisSchema(false);
        setSchemaExpanded(false);
        setisloadingSchema(false);
        console.log("done schema with error!");
      });
  };

  const checkSchemaORG = async () => {
    const config = {
      headers: {
        Accept: "application/json",
      },
    };

    await axios
      //.get("/schema?url=" + target, config)
      .get("https://heyadigi.cloud/schema?url=" + target, config)
      .then((response) => {
        //console.log("Schema.org:");
        //console.log("Response Data: ", response.data);
        //console.log("schema number: ", response.data.length)
        if (response.data.length > 0) {
          setisSchemaORG(true);
          setSchemaORGData(response.data);
        } else {
          setisSchemaORG(false);
          setSchemaORGData(null);
        }
        setSchemaORGExpanded(false);
        setisloadingSchemaORG(false);
        console.log("done with schema.org!");
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        if (schemaORGData) {
          setSchemaORGData(null);
        }
        if (isSchemaORG) setisSchemaORG(false);
        setSchemaORGExpanded(false);
        setisloadingSchemaORG(false);
        console.log("done schema.org with error!");
      });
  };

  const faviconchecker = async () => {
    const config = {
      headers: {
        Accept: "application/json",
      },
    };

    await axios
      //.get("/schema?url=" + target, config)
      .get("https://heyadigi.cloud/favicon?url=" + target, config)
      .then((response) => {
        //console.log("Favicon:");
        //console.log(response.data);
        //console.log("Favicon: ", response.data["score"]);
        //console.log("Response Data: ", response.data["details"]);
        if (response.data) {
          if (response.data["score"]) {
            setisFavicon(true);
          } else {
            setisFavicon(false);
          }
          setFaviconData(response.data["details"]);
          //console.log("favicon response data: ", response.data["details"]);
          //console.log("JSON.stringify: ");
          //let jsonString = JSON.stringify(response.data);
          //console.log(jsonString);
          //let json_size = Object.keys(jsonString).length;
          //console.log("FaviconData length: ", json_size);
          //console.log("faviconData: ", faviconData);
        } else {
          setisFavicon(false);
          setFaviconData(null);
        }
        setFaviconExpanded(false);
        setisloadingFavicon(false);
        console.log("done with favicon!");
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        if (faviconData) {
          setFaviconData(null);
        }
        if (isFavicon) setisFavicon(false);
        setFaviconExpanded(false);
        setisloadingFavicon(false);
        console.log("done favicon with error!");
      });
  };

  const sitemapchecker = async () => {
    const config = {
      headers: {
        Accept: "application/json",
      },
    };

    await axios
      .get("https://heyadigi.cloud/sitemap?url=" + target, config)
      .then((response) => {
        if (response.data) {
          if (response.data["connection"]) {
            if (response.data["status"] === "passed") {
              setisSitemap(true);
              setSitemapData(response.data["details"]);
            } else if (response.data["status"] === "failed") {
              setisSitemap(false);
              setSitemapData(response.data["details"]);
            }
          } else {
            setisSitemap(false);
            setSitemapData("連線問題，請稍候再試！");
          }
        } else {
          setisSitemap(false);
          setSitemapData("連線問題，請稍候再試！");
        }
        //console.log("sitemap connection: ", response.data["connection"]);
        //console.log("sitemap exists: ", response.data["status"]);
        //console.log("sitemap details: ", response.data["details"]);
        setisloadingSitemap(false);
        console.log("done with sitemap!");
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        if (sitemapData) {
          setSitemapData(null);
        }
        if (isSitemap) setisSitemap(false);
        //resumesitemapchecker();
        setisloadingSitemap(false);
        console.log("done sitemap with error!");
      });
  };

  const headingtagschecker = async () => {
    const config = {
      headers: {
        Accept: "application/json",
      },
    };

    await axios
      .get("https://heyadigi.cloud/headingtags?url=" + target, config)
      .then((response) => {
        if (response.data) {
          if (response.data["connection"]) {
            if (response.data["status"] === "passed") {
              setisHeadingtags(true);
              setHeadingtagsData(response.data["details"]);
            } else if (response.data["status"] === "failed") {
              setisHeadingtags(false);
              setHeadingtagsData(response.data["details"]);
            }
          } else {
            setisHeadingtags(false);
            setHeadingtagsData("連線問題，請稍候再試！");
          }
        } else {
          setisHeadingtags(false);
          setHeadingtagsData("連線問題，請稍候再試！");
        }
        //console.log("heading tags details: ", response.data["details"]);
        setHeadingTagsExpanded(false);
        setisloadingHeadingtags(false);
        console.log("done with heading tags!");
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        if (headingtagsData) {
          setHeadingtagsData(null);
        }
        if (isHeadingtags) setisHeadingtags(false);
        setHeadingTagsExpanded(false);
        setisloadingHeadingtags(false);
        console.log("done heading tags with error!");
      });
  };

  const keywordschecker = async () => {
    const config = {
      headers: {
        Accept: "application/json",
      },
    };

    await axios
      .get("https://heyadigi.cloud/keywords?url=" + target, config)
      .then((response) => {
        if (response.data) {
          if (response.data["keywords"]) {
            setisKeywords(true);
            setKeywordsData(response.data);
          } else {
            setisKeywords(false);
            setKeywordsData("連線問題，請稍候再試！");
          }
        } else {
          setisKeywords(false);
          setKeywordsData("連線問題，請稍候再試！");
        }
        //console.log("heading tags details: ", response.data["details"]);
        setKeywordsExpanded(false);
        setisloadingKeywords(false);
        console.log("done with keywords!");
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        if (keywordsData) {
          setKeywordsData(null);
        }
        if (isKeywords) setisKeywords(false);
        setKeywordsExpanded(false);
        setisloadingKeywords(false);
        console.log("done keywords with error!");
      });
  };

  const backlinkschecker = async () => {
    const config = {
      headers: {
        Accept: "application/json",
      },
    };

    await axios
      .get("https://heyadigi.cloud/backlinks?url=" + target, config)
      .then((response) => {
        if (response.data) {
          if (response.data["top_backlinks"].length === 0) {
            setisBacklinks(false);
            setBacklinksData("連線問題，請稍候再試！");
          } else {
            setisBacklinks(true);
            setBacklinksData(response.data);
          }
        } else {
          setisBacklinks(false);
          setBacklinksData("連線問題，請稍候再試！");
        }
        //console.log("heading tags details: ", response.data["details"]);
        setBacklinksExpanded(false);
        setisloadingBacklinks(false);
        console.log("done with backlinks!");
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        if (backlinksData) {
          setBacklinksData(null);
        }
        if (isBacklinks) setisBacklinks(false);
        setBacklinksExpanded(false);
        setisloadingBacklinks(false);
        console.log("done backlinks with error!");
      });
  };

  const fetch = async () => {
    backlinkschecker(); // backlinks
    keywordschecker(); // keywords
    headingtagschecker(); // heading Tags
    sitemapchecker(); // sitemap
    faviconchecker(); // favicon
    checkSchemaORG(); // structured data, schema.org
    checkAMP(); // AMP
    checkSchema(); // social schema tags
    axiospagespeedmobile(); // mobile
    axiospagespeed(); // desktop
    //sslChecker(target); // ssl checker (all)
  };

  useEffect(() => {
    if (target && !isloading && !isloadingmobile && !isloadingAMP && !isloadingSchema && !isloadingSchemaORG && !isloadingFavicon && !isloadingSitemap && !isloadingHeadingtags && !isloadingKeywords && !isloadingBacklinks) {
      console.log("target: " + target);
      setisloading(true);
      setisloadingmobile(true);
      setisloadingAMP(true);
      setisloadingSchema(true);
      setisloadingSchemaORG(true);
      setisloadingFavicon(true);
      setisloadingSitemap(true);
      setisloadingHeadingtags(true);
      setisloadingKeywords(true);
      setisloadingBacklinks(true);
      setErrorMessage("");
      fetch();
    }
  }, [target, isloading, isloadingmobile, isloadingAMP, isloadingSchema, isloadingSchemaORG, isloadingFavicon, isloadingSitemap, isloadingHeadingtags, isloadingKeywords, isloadingBacklinks]);

  const isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };

  const imgList = (data) => {
    //console.log("img list:");
    //console.log(data.details.items);

    return (
      <>
        {data.details.items &&
          data.details.items.map((item, index) => (
            <div key={index} className="flex-container-list">
              <div className="flex-items-list-score">
                <img
                  /*src={
                    item.node.snippet.indexOf("http") === -1 
                      ? webURL +
                        "/" +
                        item.node.snippet
                          .slice(item.node.snippet.indexOf("src=") + 4, -1)
                          .split(" ")[0]
                          .replace(">", "")
                          .replace('"/', "")
                          .replace('"', "")
                          .replace('"', "")
                      : item.node.snippet
                          .slice(item.node.snippet.indexOf("src=") + 4, -1)
                          .split(" ")[0]
                          .replace(">", "")
                          .replace('"/', "")
                          .replace('"', "")
                          .replace('"', "")
                  }*/
                  width={"100px"}
                  alt={""}
                />
              </div>

              <div className="flex-items-list-details">
                <div className="item_section_title">
                  未通過稽核的圖片元素 ({index + 1}/{data.details.items.length})
                </div>
                <div className="image_alt_item_label_title">
                  {item.node.selector}
                </div>
                <div className="image_alt_label_description">
                  {/*item.node.snippet*/}
                  {/*console.log("snippet: " + item.node.snippet)*/}

                  {item.node.snippet.indexOf("http") === -1
                    ? webURL +
                      "/" +
                      item.node.snippet
                        .slice(item.node.snippet.indexOf("src=") + 4, -1)
                        .split(" ")[0]
                        .replace(">", "")
                        .replace('"/', "")
                        .replace('"', "")
                        .replace('"', "")
                    : item.node.snippet
                        .slice(item.node.snippet.indexOf("src=") + 4, -1)
                        .split(" ")[0]
                        .replace(">", "")
                        .replace('"/', "")
                        .replace('"', "")
                        .replace('"', "")}
                </div>
              </div>
            </div>
          ))}
      </>
    );
  };

  const schemaList = (data) => {
    //console.log("schema list:");

    return (
      <>
        {isSchema /*&&
          data.items[0].link === webURL + "/"*/ &&
          Object.entries(data.items[0].pagemap.metatags[0]).map(
            ([key, value]) => (
              <div key={key} className="flex-container-list">
                <div className="flex-items-list-score">
                  <img width={"100px"} alt={""} />
                </div>

                <div className="flex-items-list-details">
                  <div className="item_section_title">
                    Meta Tags (
                    {Object.keys(data.items[0].pagemap.metatags[0]).indexOf(
                      key
                    ) + 1}
                    /{Object.keys(data.items[0].pagemap.metatags[0]).length})
                  </div>
                  <div className="image_alt_item_label_title">{key}</div>
                  <div className="image_alt_label_description">{value}</div>
                </div>
              </div>
            )
          )}
      </>
    );
  };

  const schemaORGList = (data) => {
    //console.log("schemaORG list:");

    return (
      <>
        {isSchemaORG &&
          data.map((item, index) => (
            <div key={index} className="flex-container-list">
              <div className="flex-items-list-score">
                <img width={"100px"} alt={""} />
              </div>

              <div className="flex-items-list-details">
                <div className="item_section_title">
                  結構化資料 ({index + 1}/{data.length})
                </div>
                <div className="image_alt_item_label_title">
                  類型 {index + 1}
                </div>
                <div className="image_alt_label_description">{item}</div>
              </div>
            </div>
          ))}
      </>
    );
  };

  const faviconList = (data) => {
    //console.log("favicon list:");
    //console.log(data["safari_pinned_tab"].name);
    //console.log(data["safari_pinned_tab"].status);
    //console.log(data["safari_pinned_tab"].messages);

    //let platforms_num = Object.keys(data).length;
    //console.log("platforms number: ", platforms_num);

    return (
      <>
        {data &&
          Object.entries(data).map(([key, value], index) => (
            <div key={index} className="flex-container-list">
              <div className="flex-items-list-score">
                <img width={"100px"} alt={""} />
              </div>

              <div className="flex-items-list-details">
                <div className="item_section_title">
                  網站圖示 Favicon ({index + 1}/
                  {faviconData && Object.keys(faviconData).length})
                </div>
                <div className="image_alt_item_label_title">
                  {value.name +
                    " (" +
                    (value.status === "success"
                      ? "完全正確"
                      : value.status === "info"
                      ? "未完全正確"
                      : value.status === "no_icon"
                      ? "網站圖示欠缺"
                      : value.status === "error"
                      ? "網站圖示錯誤"
                      : null) +
                    ")"}
                </div>

                <pre>
                  {value &&
                    Object.entries(value).map(([key1, value1]) =>
                      key1 === "messages"
                        ? value1 &&
                          Object.entries(value1).map(([key2, value2]) =>
                            key2 === "success"
                              ? "網站圖示 Favicon 完全正確：\n" +
                                value2
                                  .map(
                                    (item, index3) =>
                                      "(" + String(index3 + 1) + ") " + item
                                  )
                                  .join(", \n") +
                                "\n\n"
                              : key2 === "info"
                              ? "網站圖示 Favicon 補充資訊：\n" +
                                value2
                                  .map(
                                    (item, index4) =>
                                      "(" + String(index4 + 1) + ") " + item
                                  )
                                  .join(", \n") +
                                "\n\n"
                              : key2 === "no_icon"
                              ? "欠缺的網站圖示 Favicon：\n" +
                                value2
                                  .map(
                                    (item, index5) =>
                                      "(" + String(index5 + 1) + ") " + item
                                  )
                                  .join(", \n") +
                                "\n\n"
                              : key2 === "error"
                              ? "錯誤的網站圖示 Favicon：\n" +
                                value2
                                  .map(
                                    (item, index6) =>
                                      "(" + String(index6 + 1) + ") " + item
                                  )
                                  .join(", \n") +
                                "\n\n"
                              : null
                          )
                        : null
                    )}
                </pre>
              </div>
            </div>
          ))}
      </>
    );
  };

  const headingtagsList = (data) => {
    return (
      <>
        {data &&
          Object.entries(data).map(([key, value], index) => (
            <div key={index} className="flex-container-list">
              <div className="flex-items-list-score">
                <img width={"100px"} alt={""} />
              </div>

              <div className="flex-items-list-details">
                <div className="item_section_title">
                  標題標籤 Heading Tags ({index + 1}/
                  {headingtagsData && Object.keys(headingtagsData).length})
                </div>
                <div className="image_alt_item_label_title">
                  {"標題標籤 " + key}
                </div>
                <pre>
                  {value &&
                    value
                      .map(
                        (item, index2) => "(" + String(index2 + 1) + ") " + item
                      )
                      .join(", \n") + "\n\n"}
                </pre>
              </div>
            </div>
          ))}
      </>
    );
  };

  const keywordsList = (data) => {
    return (
      <>
        {data["keywords"] &&
          Object.entries(data["keywords"]).map(([key, value], index) => (
            <div key={index} className="flex-container-list">
              <div className="flex-items-list-score">
                <img width={"100px"} alt={""} />
              </div>

              <div className="flex-items-list-details">
                <div className="item_section_title">
                  關鍵字 Keywords ({index + 1}/
                  {data["keywords"] && Object.keys(data["keywords"]).length})
                </div>
                <div className="image_alt_item_label_title">
                  {value && value["keyword"]}
                </div>
                <pre>
                  {value &&
                    "出現次數：" +
                      value["count"] +
                      "\n" +
                      "權重：" +
                      value["weight"] +
                      "\n" +
                      "網頁標題：" +
                      value["in_title"] +
                      "\n" +
                      "網頁描述：" +
                      value["in_description"]}
                </pre>
              </div>
            </div>
          ))}
      </>
    );
  };

  const keywordsTableList = (data) => {
    return (
      <>
        <div className="flex-container-list">
          <div className="flex-items-list-score">
            <img width={"100px"} alt={""} />
          </div>

          <div className="flex-items-list-details">
            <div className="item_section_title">關鍵字列表</div>
            <pre>
              <div className="wrap">
                <table>
                  <tbody>
                    <tr>
                      <th>排名</th>
                      <th className="keywords_th">關鍵字</th>
                      <th>出現次數</th>
                      <th>權重</th>
                      <th>網頁標題</th>
                      <th>網頁描述</th>
                    </tr>

                    {data["keywords"] &&
                      Object.entries(data["keywords"]).map(
                        ([key, value], index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="keywords_td">
                              {value && value["keyword"]}
                            </td>
                            <td>{value && value["count"]}</td>
                            <td>{value && value["weight"]}</td>
                            <td>{value && value["in_title"]}</td>
                            <td>{value && value["in_description"]}</td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </pre>
          </div>
        </div>
      </>
    );
  };

  const backlinksTableList = (data) => {
    return (
      <>
        <div className="flex-container-list">
          <div className="flex-items-list-score">
            <img width={"100px"} alt={""} />
          </div>

          <div className="flex-items-list-details">
            <div className="item_section_title">反向連結列表</div>
            <pre>
              <div className="wrap">
                <table>
                  <tbody>
                    <tr>
                      <th>排名</th>
                      <th className="backlinks_th">連結標題</th>
                      <th className="backlinks_th">連結網址</th>
                      <th className="backlinks_th">連結文字</th>
                      <th className="backlinks_th">連結目標網址</th>
                      <th>nofollow</th>
                      <th>連結網頁權重</th>
                      <th>連結網域權重</th>
                      <th>偵測日期</th>
                    </tr>

                    {data["top_backlinks"] &&
                      Object.entries(data["top_backlinks"]).map(
                        ([key, value], index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="backlinks_td">
                              {value && value["page_title"]}
                            </td>
                            <td className="backlinks_td">{value && value["page_location"]}</td>
                            <td className="backlinks_td">{value && value["anchor_text"]}</td>
                            <td className="backlinks_td">{value && value["anchor_destination"]}</td>
                            <td>{value && value["nofollow"]}</td>
                            <td>{value && value["page_authority"]}</td>
                            <td>{value && value["domain_authority"]}</td>
                            <td>{value && value["found"]}</td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </pre>
          </div>
        </div>
      </>
    );
  };

  const handleSubmit = (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    let retrieved_url = e.target.elements.input_url.value;
    if (!retrieved_url) {
      setErrorMessage("請輸入有效的網址");
      e.target.elements.input_url.value = "";
      return false;
    }

    if (!isValidHttpUrl(retrieved_url)) {
      retrieved_url = "https://" + retrieved_url;
      //console.log("Prefixed: " + retrieved_url);
    }

    console.log("url: " + retrieved_url);
    e.target.elements.input_url.value = "";
    setTarget(retrieved_url);
    /*console.log(
      "last character of url: " +
        retrieved_url.substring(retrieved_url.length - 1, retrieved_url.length)
    );*/
    if (
      retrieved_url.substring(
        retrieved_url.length - 1,
        retrieved_url.length
      ) === "/"
    ) {
      setWebURL(retrieved_url.slice(0, -1));
      console.log("sliced url: ", retrieved_url.slice(0, -1));
    } else {
      setWebURL(retrieved_url);
      console.log("no sliced url: ", retrieved_url);
    }
  };

  const showResult = () => {
    if (isloading) {
      return <h4>正在執行分析(電腦)...</h4>;
    } else if (!page) {
      if (error_message) {
        return <h4>{error_message}</h4>;
      } else {
        return null;
      }
    } else {
      return (
        <div className="showResults">
          <h4>分析的網貢: {page["id"]}</h4>
          <div className="flex-container">
            {showPagespeedGauge(
              page["lighthouseResult"].categories.performance
            )}

            {showPagespeedGauge(
              page["lighthouseResult"].categories.accessibility
            )}

            {showPagespeedGauge(
              page["lighthouseResult"].categories["best-practices"]
            )}

            {showPagespeedGauge(page["lighthouseResult"].categories.seo)}

            {/*<ItemList data={jsonData} />*/}
          </div>

          {showCharsetFactors(
            "字元編碼 Charset",
            page["lighthouseResult"].audits.charset
          )}

          {/*showFactors(
            "字元編碼 Charset",
            page["lighthouseResult"].audits.charset
          )*/}

          {showSSLFactors(
            "安全連線 SSL",
            page["lighthouseResult"].audits["is-on-https"]
          )}

          {/*showFactors(
            "安全連線 SSL",
            page["lighthouseResult"].audits["is-on-https"]
          )*/}

          {showCanonicalFactors(
            "標準網址 Canonical",
            page["lighthouseResult"].audits.canonical
          )}

          {/*showFactors(
            "標準網址 Canonical",
            page["lighthouseResult"].audits.canonical
          )*/}

          {showTitleFactors("網頁標題 Meta Title", page["lighthouseResult"].audits["document-title"])}

          {/*showFactors(
            "Meta Title",
            page["lighthouseResult"].audits["document-title"]
          )*/}

          {showDescriptionFactors("網頁描述 Meta Description", page["lighthouseResult"].audits["meta-description"])}

          {/*showFactors(
            "Meta Description",
            page["lighthouseResult"].audits["meta-description"]
          )*/}

          {showHeadingTagsFactors("標題標籤 Heading Tags")}
          {isHeadingTagsExpanded && headingtagsList(headingtagsData)}

          {/*showFactors(
            "Heading Tags",
            page["lighthouseResult"].audits["heading-order"]
          )*/}

          {showKeywordsFactors("關鍵字密度 Keyword Density")}
          {isKeywordsExpanded && keywordsTableList(keywordsData)}

          {showBacklinksFactors("反向連結 Backlinks")}
          {isBacklinksExpanded && backlinksTableList(backlinksData)}          

          {showImageALTFactors(
            "圖片替代文字 Image ALT",
            page["lighthouseResult"].audits["image-alt"]
          )}

          {/*showFactors(
            "圖片替代文字 Image ALT",
            page["lighthouseResult"].audits["image-alt"]
          )*/}
          {isImgAltExpanded &&
            imgList(page["lighthouseResult"].audits["image-alt"])}

          {showRobotsFactors(
            "Robots.txt 檔案",
            page["lighthouseResult"].audits["robots-txt"]
          )}

          {/*showFactors(
            "Robots.txt 檔案",
            page["lighthouseResult"].audits["robots-txt"]
          )*/}

          {showLangFactors(
            "HTML 語系屬性",
            page["lighthouseResult"].audits["html-lang-valid"]
          )}

          {/*showFactors(
            "HTML 語系屬性",
            page["lighthouseResult"].audits["html-lang-valid"]
          )*/}
          {/*page["lighthouseResult"].audits["html-has-lang"].score === 1
            ? showFactors(
                "語言",
                page["lighthouseResult"].audits["html-lang-valid"]
              )
            : ""*/}

          {showDoctypeFactors(
            "文件類型宣告 Doctype",
            page["lighthouseResult"].audits.doctype
          )}

          {/*showFactors("Doctype", page["lighthouseResult"].audits.doctype)*/}

          {showViewportFactors(
            "Meta Viewport 標籤",
            page["lighthouseResult"].audits.viewport
          )}

          {/*showFactors(
            "Viewport Meta",
            page["lighthouseResult"].audits.viewport
          )*/}

          {showSchemaFactors("社群平台結構化資料")}
          {isSchemaExpanded && schemaList(schemaData)}

          {showSchemaORGFactors("結構化資料")}
          {isSchemaORGExpanded && schemaORGList(schemaORGData)}

          {showFaviconFactors("網站圖示 Favicon")}
          {isFaviconExpanded && faviconList(faviconData)}

          {showSitemapFactors("網站地圖 Sitemap")}

          {/*showAMPFactors("AMP 測試")*/}
        </div>
      );
    }
  };

  const showResultMobile = () => {
    if (isloadingmobile) {
      return <h4>正在執行分析(行動裝置)...</h4>;
    } else if (!page_mobile) {
      if (error_message) {
        return <h4>{error_message}</h4>;
      } else {
        return null;
      }
    } else {
      return (
        <div className="showResults">
          <h4>分析的網貢: {page_mobile["id"]}</h4>
          <div className="flex-container">
            {showPagespeedGauge(
              page_mobile["lighthouseResult"].categories.performance
            )}

            {showPagespeedGauge(
              page_mobile["lighthouseResult"].categories.accessibility
            )}

            {showPagespeedGauge(
              page_mobile["lighthouseResult"].categories["best-practices"]
            )}

            {showPagespeedGauge(page_mobile["lighthouseResult"].categories.seo)}

            {/*<ItemList data={jsonData} />*/}
          </div>

          {showCharsetFactors(
            "字元編碼 Charset",
            page_mobile["lighthouseResult"].audits.charset
          )}

          {/*showFactors(
            "字元編碼 Charset",
            page_mobile["lighthouseResult"].audits.charset
          )*/}

          {showSSLFactors(
            "安全連線 SSL",
            page_mobile["lighthouseResult"].audits["is-on-https"]
          )}

          {/*showFactors(
            "安全連線 SSL",
            page_mobile["lighthouseResult"].audits["is-on-https"]
          )*/}

          {showCanonicalFactors(
            "標準網址 Canonical",
            page_mobile["lighthouseResult"].audits.canonical
          )}

          {/*showFactors(
            "標準網址 Canonical",
            page_mobile["lighthouseResult"].audits.canonical
          )*/}

          {showTitleFactors("網頁標題 Meta Title", page_mobile["lighthouseResult"].audits["document-title"])}

          {/*showFactors(
            "Meta Title",
            page_mobile["lighthouseResult"].audits["document-title"]
          )*/}

          {showDescriptionFactors("網頁描述 Meta Description", page_mobile["lighthouseResult"].audits["meta-description"])}

          {/*showFactors(
            "Meta Description",
            page_mobile["lighthouseResult"].audits["meta-description"]
          )*/}

          {showHeadingTagsFactors("標題標籤 Heading Tags")}
          {isHeadingTagsExpanded && headingtagsList(headingtagsData)}

          {/*showFactors(
            "Heading Tags",
            page_mobile["lighthouseResult"].audits["heading-order"]
          )*/}

          {showKeywordsFactors("關鍵字密度 Keyword Density")}
          {isKeywordsExpanded && keywordsTableList(keywordsData)}

          {showBacklinksFactors("反向連結 Backlinks")}
          {isBacklinksExpanded && backlinksTableList(backlinksData)}

          {showImageALTFactors(
            "圖片替代文字 Image ALT",
            page_mobile["lighthouseResult"].audits["image-alt"]
          )}

          {/*howFactors(
            "圖片替代文字 Image ALT",
            page_mobile["lighthouseResult"].audits["image-alt"]
          )*/}
          {isImgAltExpanded &&
            imgList(page_mobile["lighthouseResult"].audits["image-alt"])}

          {showRobotsFactors(
            "Robots.txt 檔案",
            page_mobile["lighthouseResult"].audits["robots-txt"]
          )}

          {/*showFactors(
            "Robots.txt 檔案",
            page_mobile["lighthouseResult"].audits["robots-txt"]
          )*/}

          {showLangFactors(
            "HTML 語系屬性",
            page_mobile["lighthouseResult"].audits["html-lang-valid"]
          )}

          {/*showFactors(
            "HTML 語系屬性",
            page_mobile["lighthouseResult"].audits["html-lang-valid"]
          )*/}
          {/*page_mobile["lighthouseResult"].audits["html-has-lang"].score === 1
            ? showFactors(
                "語言",
                page_mobile["lighthouseResult"].audits["html-lang-valid"]
              )
            : ""*/}

          {showDoctypeFactors(
            "文件類型宣告 Doctype",
            page_mobile["lighthouseResult"].audits.doctype
          )}

          {/*showFactors(
            "Doctype",
            page_mobile["lighthouseResult"].audits.doctype
          )*/}

          {showViewportFactors(
            "Meta Viewport 標籤",
            page_mobile["lighthouseResult"].audits.viewport
          )}

          {/*showFactors(
            "Viewport Meta",
            page_mobile["lighthouseResult"].audits.viewport
          )*/}

          {showSchemaFactors("社群平台結構化資料")}
          {isSchemaExpanded && schemaList(schemaData)}

          {showSchemaORGFactors("結構化資料")}
          {isSchemaORGExpanded && schemaORGList(schemaORGData)}

          {showFaviconFactors("網站圖示 Favicon")}
          {isFaviconExpanded && faviconList(faviconData)}

          {showSitemapFactors("網站地圖 Sitemap")}

          {/*showAMPFactors("AMP 測試")*/}
        </div>
      );
    }
  };

  const showPagespeedGauge = (pageData) => {
    return (
      <div className="flex-items">
        <LiquidGauge score={100 * pageData.score} radius={70} yoffset={13} />
        <div className="label_title">{pageData.title}</div>
      </div>
    );
  };

  const showFactors = (secTitle, pageData) => {
    /*let score = pageData.score;

    if (pageData.score === null) {
      score = -1;
    }*/

    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            //score={100 * score}
            score={pageData.score === null ? -100 : 100 * pageData.score}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {/*score === -1 ? "不適用" : pageData.title*/}
            {pageData.title}
          </div>
          <div className="label_description">{pageData.description}</div>
          {secTitle === "圖片替代文字 Image ALT" &&
          pageData.details &&
          pageData.details.items.length !== 0 ? (
            <div
              className="click_for_more"
              onClick={() => setImgAltExpanded(!isImgAltExpanded)}
            >
              {isImgAltExpanded ? "收合" : "顯示"} (共{" "}
              {pageData.details.items.length} 個)
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const showCharsetFactors = (secTitle, pageData) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={pageData.score === null || pageData.score === 0 ? 0 : 100 * pageData.score}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {pageData.score !== 1
              ? "錯誤的 Charset 字元編碼"
              : "正確的 Charset 字元編碼"}
          </div>
          <div className="label_description">
            正確的 Charset
            字元編碼設定，能夠指定瀏覽器顯示網頁時，採用的字元編碼。錯誤的
            Charset 字元編碼設定，會讓網頁出現亂碼，令使用者難以辨識內容。
          </div>
        </div>
      </div>
    );
  };

  const showSSLFactors = (secTitle, pageData) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={pageData.score === null || pageData.score === 0 ? 0 : 100 * pageData.score}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {pageData.score !== 1
              ? "未使用 HTTPS (不安全的連線)"
              : "使用 HTTPS (安全的連線)"}
          </div>
          <div className="label_description">
            HTTPS 是使用 TLS 加密的 HTTP，使用 TLS (SSL) 對正常的 HTTP
            請求和回應進行加密，使其更加安全可靠。
            <br />
            <br />
            (1) 使用 HTTPS 的網站對使用者來說更加值得信賴。
            <br />
            (2) HTTPS 對於使用者和網站擁有者來說都更安全。
            <br />
            (3) HTTPS 對網站進行驗證。
          </div>
        </div>
      </div>
    );
  };

  const showCanonicalFactors = (secTitle, pageData) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={pageData.score === null || pageData.score === 0 ? 0 : 100 * pageData.score}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {pageData.score !== 1
              ? "無有效的 Canonical 標籤"
              : "具備有效的 Canonical 標籤"}
          </div>
          <div className="label_description">
            標準網址 Canonical 可指定要在搜尋結果頁 (SERP) 中顯示哪個網址。
            <br />
            <br />
            某一個網頁若可以透過多個不同的網址進入，或是不同網頁間具有高度相似的內容時，Google
            便會認為這是內容重複，Google
            會從中自行選出一個標準網址，將其他網址視為重複內容並降低搜尋頻率，但
            Google
            自行選擇的標準網址與目標經營網頁未必相同，且可能會影響其他網頁的 SEO
            權重，分散 SEO 權重，造成排名下降。
          </div>
        </div>
      </div>
    );
  };

  const showImageALTFactors = (secTitle, pageData) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={pageData.score === null || pageData.score === 0 ? 0 : 100 * pageData.score}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {pageData.score !== 1 ? "圖片缺少 Alt 屬性" : "圖片具有 Alt 屬性"}
          </div>
          <div className="label_description">
            圖片替代文字，常稱為 Alt 屬性、Alt
            描述，用於當網路速度太慢、圖片檔案出錯、瀏覽器禁用圖像等原因，導致圖片顯示失效的時候，圖片替代文字可以描述圖像的內容，作為輔助措施。同時，可以幫助搜尋引擎理解圖片內容，有助於
            SEO 排名。
          </div>
          {pageData.details && pageData.details.items.length !== 0 ? (
            <div
              className="click_for_more"
              onClick={() => setImgAltExpanded(!isImgAltExpanded)}
            >
              {isImgAltExpanded ? "收合" : "顯示"} (共{" "}
              {pageData.details.items.length} 個)
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const showRobotsFactors = (secTitle, pageData) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={pageData.score === null || pageData.score === 0 ? 0 : 100 * pageData.score}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {pageData.score !== 1 ? "robots.txt 無效" : "robots.txt 有效"}
          </div>
          <div className="label_description">
            如果 robots.txt 檔案格式錯誤，爬蟲可能無法瞭解你偏好的網站檢索方式。
            <br />
            <br />
            位於網站根目錄下的 robots.txt
            是一個純文字檔案，它告訴爬蟲哪些部分的網站是可以被抓取的，哪些是不允許的，控制爬蟲可以抓取網站的資訊，避免重要資源浪費在不必要的頁面，或是防止敏感資訊被索引。透過有效配置
            robots.txt
            檔案，可以在不影響用戶體驗的前提下，優化網站的搜尋引擎排名。
          </div>
        </div>
      </div>
    );
  };

  const showLangFactors = (secTitle, pageData) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={pageData.score === null || pageData.score === 0 ? 0 : 100 * pageData.score}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {pageData.score !== 1
              ? "無效的 HTML lang 屬性"
              : "有效的 HTML lang 屬性"}
          </div>
          <div className="label_description">
            HTML lang
            屬性的功用為標註網頁的語系，讓瀏覽器能更正確的解析與編碼頁面內容。
          </div>
        </div>
      </div>
    );
  };

  const showDoctypeFactors = (secTitle, pageData) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={pageData.score === null || pageData.score === 0 ? 0 : 100 * pageData.score}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {pageData.score !== 1
              ? "缺少 HTML Doctype 文件類型宣告"
              : "具備有效的 HTML Doctype 文件類型宣告"}
          </div>
          <div className="label_description">
            Doctype 是 Document Type
            的縮寫，意思是文件類型，也就是告訴瀏覽器本頁面的 HTML 或 XHTML
            等是用什麼版本的語言，以便瀏覽器正確解讀網頁。網頁若缺少 HTML
            Doctype 文件類型宣告，將觸發相容模式。
          </div>
        </div>
      </div>
    );
  };

  const showViewportFactors = (secTitle, pageData) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={pageData.score === null || pageData.score === 0 ? 0 : 100 * pageData.score}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {pageData.score !== 1
              ? "缺少包含 width 或 initial-scale 的 Meta Viewport 標記"
              : "具備有效的的 Meta Viewport 標記"}
          </div>
          <div className="label_description">
            Viewport 標籤是響應式網頁 (RWD)
            設計用來設定行動裝置瀏覽網頁的寬度和基本設定，可以根據行動裝置螢幕大小將應用程式最佳化。
          </div>
        </div>
      </div>
    );
  };

  const showSchemaFactors = (secTitle) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={isSchema ? 100 : 0}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {isSchema
              ? "此頁面有社群平台結構化資料"
              : "此頁面上沒有社群平台結構化資料"}
          </div>
          <div className="label_description">
            {isSchema
              ? "透過為頁面添加社群平台結構化資料，可以更美觀地分享網站到社群平台。"
              : "透過為頁面添加社群平台結構化資料，可以更美觀地分享網站到社群平台。"}
          </div>

          {isSchema &&
          Object.keys(schemaData.items[0].pagemap.metatags[0]).length !== 0 ? (
            <div
              className="click_for_more"
              onClick={() => setSchemaExpanded(!isSchemaExpanded)}
            >
              {isSchemaExpanded ? "收合" : "顯示"} (共{" "}
              {Object.keys(schemaData.items[0].pagemap.metatags[0]).length} 個)
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const showSchemaORGFactors = (secTitle) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={isSchemaORG ? 100 : 0}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {isSchemaORG ? "此頁面有結構化資料" : "此頁面上沒有結構化資料"}
          </div>
          <div className="label_description">
            {isSchemaORG
              ? "透過為頁面添加結構化資料，Google 將更輕鬆地理解內容。它還有助於提高 SERP 中的可見性。"
              : "透過為頁面添加結構化資料，Google 將更輕鬆地理解內容。它還有助於提高 SERP 中的可見性。"}
          </div>

          {isSchemaORG && schemaORGData.length !== 0 ? (
            <div
              className="click_for_more"
              onClick={() => setSchemaORGExpanded(!isSchemaORGExpanded)}
            >
              {isSchemaORGExpanded ? "收合" : "顯示"} (共 {schemaORGData.length}{" "}
              個)
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const showFaviconFactors = (secTitle) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={isFavicon ? 100 : 0}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {isFavicon
              ? "此頁面有網站圖示 Favicon 圖片"
              : "此頁面上沒有網站圖示 Favicon 圖片"}
          </div>
          <div className="label_description">
            {isFavicon
              ? "此頁面有網站圖示 Favicon，透過直接使用企業名稱或者 Logo 設計為 Favicon，可以讓用戶直接認出您的企業，提高企業整體的被識別率，同時 Favicon 也是網站標識之一，也可以達到整個網站設計統一的效果。"
              : "此頁面上沒有網站圖示 Favicon，請直接使用企業名稱或者 Logo 設計為 Favicon，可以讓用戶直接認出您的企業，提高企業整體的被識別率，同時 Favicon 也是網站標識之一，也可以達到整個網站設計統一的效果。"}
          </div>

          {faviconData && Object.keys(faviconData).length !== 0 ? (
            <div
              className="click_for_more"
              onClick={() => setFaviconExpanded(!isFaviconExpanded)}
            >
              {isFaviconExpanded ? "收合" : "顯示"} (共{" "}
              {faviconData && Object.entries(faviconData).length} 個)
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const showSitemapFactors = (secTitle) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={isSitemap ? 100 : 0}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {isSitemap
              ? "正確的網站地圖 Sitemap 檔案"
              : "網站地圖 Sitemap 檔案有錯誤"}
          </div>
          <div className="label_description">
            {isSitemap
              ? "網站地圖 Sitemap 檔案： " + sitemapData
              : "錯誤訊息： " + sitemapData}
          </div>
        </div>
      </div>
    );
  };

  const showAMPFactors = (secTitle) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={isAMP ? 100 : 0}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {isAMP ? "AMP 網頁有效" : "這不是 AMP 網頁"}
          </div>
          <div className="label_description">
            {isAMP
              ? "提供有效 AMP 版本的網頁可在 Google 搜尋結果中呈現 AMP 特有的功能。"
              : "這個網址不是 AMP 網頁，也並未連結至 AMP 網頁。"}
          </div>
        </div>
      </div>
    );
  };

  const showHeadingTagsFactors = (secTitle) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={isHeadingtags ? 100 : 0}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {isHeadingtags ? "此頁面有 H1 標題標籤" : "此頁面無 H1 標題標籤"}
          </div>
          <div className="label_description">
            {isHeadingtags
              ? "如果您以正確的方式使用標題標籤，它們將為頁面提供正確的結構。它定義了頁面的層次結構，從最重要的標題到不太重要的標題。"
              : "如果您以正確的方式使用標題標籤，它們將為頁面提供正確的結構。它定義了頁面的層次結構，從最重要的標題到不太重要的標題。"}
          </div>

          {headingtagsData && Object.keys(headingtagsData).length !== 0 ? (
            <div
              className="click_for_more"
              onClick={() => setHeadingTagsExpanded(!isHeadingTagsExpanded)}
            >
              {isHeadingTagsExpanded ? "收合" : "顯示"} (共{" "}
              {headingtagsData && Object.entries(headingtagsData).length} 個)
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const showKeywordsFactors = (secTitle) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={isKeywords ? 100 : 0}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {isKeywords ? "可分析關鍵字密度" : "無法分析關鍵字密度"}
          </div>
          <div className="label_description">
            {isKeywords
              ? "關鍵字密度的最佳值應在 1-3% 之間。如果網址 (URL)、網頁標題 (Meta Title)、網頁描述 (Meta Description) 和標題標籤 (Heading Tags) 包含目標關鍵字，就會更理想。"
              : "關鍵字密度的最佳值應在 1-3% 之間。如果網址 (URL)、網頁標題 (Meta Title)、網頁描述 (Meta Description) 和標題標籤 (Heading Tags) 包含目標關鍵字，就會更理想。"}
          </div>

          {keywordsData["keywords"] &&
          Object.keys(keywordsData["keywords"]).length !== 0 ? (
            <div
              className="click_for_more"
              onClick={() => setKeywordsExpanded(!isKeywordsExpanded)}
            >
              {isKeywordsExpanded ? "收合" : "顯示"} (共{" "}
              {keywordsData["keywords"] &&
                Object.entries(keywordsData["keywords"]).length}{" "}
              個)
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const showBacklinksFactors = (secTitle) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={isBacklinks ? 100 : 0}
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {isBacklinks ? "可分析反向連結" : "無法分析反向連結"}
          </div>
          <div className="label_description">
            {isBacklinks
              ? "反向連結重要的原因，是因為每當有一個網站，存在指向我們網站的連結，就像我們的網站被投了一票，而越多票數，網站就越容易在 SERP 中取得好排名。"
              : "反向連結重要的原因，是因為每當有一個網站，存在指向我們網站的連結，就像我們的網站被投了一票，而越多票數，網站就越容易在 SERP 中取得好排名。"}
          </div>

          {backlinksData["top_backlinks"] &&
          Object.keys(backlinksData["top_backlinks"]).length !== 0 ? (
            <div
              className="click_for_more"
              onClick={() => setBacklinksExpanded(!isBacklinksExpanded)}
            >
              {isBacklinksExpanded ? "收合" : "顯示"} (共{" "}
              {backlinksData["top_backlinks"] &&
                Object.entries(backlinksData["top_backlinks"]).length}{" "}
              個)
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const showTitleFactors = (secTitle, pageData) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={keywordsData && keywordsData["title"] &&
              Object.keys(keywordsData["title"]).length >= 15 && Object.keys(keywordsData["title"]).length <= 70
                ? 100
                : Object.keys(keywordsData["title"]).length !== 0
                ? 0
                : pageData.score === null ? 0 : 100 * pageData.score
            }
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {keywordsData["title"] &&
            Object.keys(keywordsData["title"]).length >= 15 && Object.keys(keywordsData["title"]).length <= 70
              ? "具備有效的網頁標題" +
                " (共 " +
                Object.keys(keywordsData["title"]).length +
                " 個字元)"
              : Object.keys(keywordsData["title"]).length !== 0
              ? "網頁標題長度需要修正" +
                " (共 " +
                Object.keys(keywordsData["title"]).length +
                " 個字元)"
              : pageData.score !== null || pageData.score !== 0 ? "具備有效的網頁標題" : "無有效的網頁標題"}
          </div>
          <div className="label_description">
            {keywordsData["title"] &&
            Object.keys(keywordsData["title"]).length !== 0
              ? keywordsData["title"]
              : "網頁標題可讓使用者概略瞭解網頁內容，同時搜尋引擎也需要使用此資訊，以判斷網頁內容是否與搜尋項目有關。"}
            <br />
            <br />
            (最佳的網頁標題長度為 15-70 個字元，包括空格)
          </div>
        </div>
      </div>
    );
  };

  const showDescriptionFactors = (secTitle, pageData) => {
    return (
      <div className="flex-container-list">
        <div className="flex-items-list-score">
          <LiquidGauge
            score={keywordsData && keywordsData["description"] &&
              Object.keys(keywordsData["description"]).length >= 120 && Object.keys(keywordsData["description"]).length <= 170 
                ? 100
                : Object.keys(keywordsData["description"]).length !== 0
                ? 0
                : pageData.score === null ? 0 : 100 * pageData.score
            }
            radius={40}
            yoffset={7}
            factor={true}
          />
        </div>
        <div className="flex-items-list-details">
          <div className="item_section_title">{secTitle}</div>
          <div className="item_label_title">
            {keywordsData["description"] &&
            Object.keys(keywordsData["description"]).length >= 120 && Object.keys(keywordsData["description"]).length <= 170
              ? "具備有效的網頁描述" +
                " (共 " +
                Object.keys(keywordsData["description"]).length +
                " 個字元)"
              : Object.keys(keywordsData["description"]).length !== 0
              ? "網頁描述長度需要修正" +
                " (共 " +
                Object.keys(keywordsData["description"]).length +
                " 個字元)"
              : pageData.score !== null || pageData.score !== 0 ? "具備有效的網頁描述" : "無有效的網頁描述"}
          </div>
          <div className="label_description">
            {keywordsData["description"] &&
            Object.keys(keywordsData["description"]).length !== 0
              ? keywordsData["description"]
              : "網頁描述是以簡短文字的方式描述網站的內容，它會被顯示在搜尋結果的標題下方，設定得宜將可以提升使用者對網站的了解速度和被搜尋引擎推薦的機會。"}
            <br />
            <br />
            (最佳的網頁描述長度為 120-170 個字元，包括空格)
          </div>
        </div>
      </div>
    );
  };

  const showTab = () => {
    if (isloadingmobile || isloading) {
      return <h4>正在執行分析...</h4>;
    } else if (!page_mobile || !page) {
      if (error_message) {
        return <h4>{error_message}</h4>;
      } else {
        return null;
      }
    } else {
      return (
        <>
          <nav className="flex border-b border-gray-300">
            <TabSelector
              isActive={selectedTab === "行動裝置"}
              onClick={() => setSelectedTab("行動裝置")}
            >
              行動裝置
            </TabSelector>
            <TabSelector
              isActive={selectedTab === "電腦"}
              onClick={() => setSelectedTab("電腦")}
            >
              電腦
            </TabSelector>
          </nav>
          <div className="p-4">
            <TabPanel render="lazy" hidden={selectedTab !== "行動裝置"}>
              {showResultMobile()}
            </TabPanel>
            <TabPanel render="lazy" hidden={selectedTab !== "電腦"}>
              {showResult()}
            </TabPanel>
          </div>
        </>
      );
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>網頁效能分析</h1>
        <form method="post" onSubmit={handleSubmit}>
          <div>
            <input
              id="input_url"
              className="rounded-input"
              placeholder="輸入網頁網址"
              autoFocus={true}
            />
            <button className="button_style" type="submit">
              分析
            </button>
          </div>
        </form>
        {/*<div className="flex-container">
          <div className="flex-items">
            <LiquidGauge score="90" radius="100" label="效能" />
          </div>
          <div className="flex-items">
            <LiquidGauge score="65" radius="100" label="Speed Index" />
          </div>
        </div>*/}
      </header>
      {showTab()}
      {/*showResultMobile()*/}
      {/*showResult()*/}
    </div>
  );
}

export default App;
