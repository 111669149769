import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { Component } from "react";
import LiquidFillGauge from "react-liquid-gauge";

class LiquidGauge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.score,
      gaugeRadius: this.props.radius,
      labelText: this.props.label,
      offsetY: this.props.yoffset,
      isFactor: this.props.factor,
    };
  }

  startColor = "#FF5757"; // cornflowerblue
  endColor = "#0097B2"; // crimson

  render() {
    //const radius = 200;
    const radius = this.state.gaugeRadius;
    const yy = this.state.offsetY;
    const level = this.props.score; // hack the fill state color

    if (this.state.isFactor === true && this.state.value === 0) {
      this.endColor = "#FF5757";
      this.state.value = 100;
    }

    if (this.state.isFactor === true && this.state.value === -100) {
      this.startColor = "#939CAB";
      this.endColor = "#939CAB";
      this.state.value = 100;
      //fillColor = "#FFA500";
    }

    const interpolate = interpolateRgb(this.startColor, this.endColor);
    let fillColor = interpolate(this.state.value / 100);

    {
      /*if (level === -100 && this.state.isFactor === true) {
      fillColor = "#939CAB";
      //fillColor = "#FFA500";
    }*/
    }

    const gradientStops = [
      {
        key: "0%",
        stopColor: color(fillColor).darker(0.5).toString(),
        stopOpacity: 1,
        offset: "0%",
      },
      {
        key: "50%",
        stopColor: fillColor,
        stopOpacity: 0.75,
        offset: "50%",
      },
      {
        key: "100%",
        stopColor: color(fillColor).brighter(0.5).toString(),
        stopOpacity: 0.5,
        offset: "100%",
      },
    ];

    return (
      <div>
        <LiquidFillGauge
          style={{ margin: "0 auto" }}
          width={radius * 2}
          height={radius * 2}
          value={this.state.value}
          nlevel={level}
          percent="%"
          textSize={this.state.isFactor === true ? 0.85 : 1}
          textOffsetX={0}
          //textOffsetY={0}
          textOffsetY={yy}
          textRenderer={(props) => {
            const value = Math.round(props.value);
            const radius = Math.min(props.height / 2, props.width / 2);
            const textPixels = (props.textSize * radius) / 2;
            const valueStyle = {
              fontSize: textPixels,
            };
            const percentStyle = {
              fontSize: textPixels * 0.6,
            };

            return (
              <tspan>
                <tspan className="value" style={valueStyle}>
                  {/*value === -100 ? "" : value*/}
                  {this.state.isFactor === true && props.nlevel === -100
                    ? "不適用"
                    : props.nlevel === 0 && this.state.isFactor === true
                    ? "未通過"
                    : props.nlevel === 100 && this.state.isFactor === true
                    ? "合格"
                    : this.state.isFactor === true
                    ? props.nlevel
                    : value}
                </tspan>
                <tspan style={percentStyle}>
                  {/*value === -100 ? "" : props.percent*/}
                  {this.state.isFactor === true ? "" : props.percent}
                </tspan>
              </tspan>
            );
          }}
          riseAnimation
          waveAnimation
          waveFrequency={2}
          waveAmplitude={1}
          gradient
          gradientStops={gradientStops}
          circleStyle={{
            fill: fillColor,
          }}
          waveStyle={{
            fill: fillColor,
          }}
          textStyle={{
            fill:
              this.state.isFactor === true
                ? fillColor.toString()
                : color("#444").toString(),
            fontFamily: "Arial",
          }}
          waveTextStyle={{
            fill: color("#fff").toString(),
            fontFamily: "Arial",
          }}
          onClick={() => {
            //this.setState({ value: Math.random() * 100 });
          }}
        />
        <div
          style={{
            //fill: color("#444").toString(),
            fontFamily: "Arial, sans-serif",
            fontSize: 20,
            margin: "10px auto",
            fontWeight: 550,
            width: "100%",
          }}
        >
          {this.state.labelText}
          {/*<button
            type="button"
            className="btn btn-default btn-block"
            onClick={() => {
              this.setState({ value: Math.random() * 100 });
            }}
          >
            Refresh
          </button>*/}
        </div>
      </div>
    );
  }
}

LiquidGauge.defaultProps = {
  score: 93,
  radius: 200,
  label: "",
  yoffset: 15,
  factor: false,
};

export default LiquidGauge;
